import React from "react"
import Title from "../title-component"
import { graphql, useStaticQuery } from "gatsby"
import styles from "../../css/tenido-component.module.css"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"
import Pdf from "../../assets/catalogoColoresBravoHats.pdf"
const getTenido = graphql`
  query {
    tenido: file(relativePath: { eq: "sombrerosFondo3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Tenido = () => {
  const data = useStaticQuery(getTenido)
  const tenidoImagen = data.tenido.childImageSharp.fluid
  return (
    <section className={styles.tenido}>
      <Title
        title={<FormattedMessage id="tenidoTitle1" />}
        subtitle={<FormattedMessage id="tenidoTitle2" />}
      />
      <p className={styles.descripcion}>
        <FormattedMessage id="tenidoText1" />
      </p>

      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Abour company" /> */}
            <Img fluid={tenidoImagen} alt="Teñido del Panama Hat" />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>
            <FormattedMessage id="tenidoTitle3" />
          </h4>
          <p className={styles.text}>
            <FormattedMessage id="tenidoText2" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="tenidoText3" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="tenidoText4" />
          </p>
          <a
            href={Pdf}
            to="/colores"
            className="btn-blue"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="verCatalogoColores" />
          </a>
        </article>
      </div>
    </section>
  )
}

export default Tenido
