import React from "react"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import TenidoComponent from "../components/Tenido/tenido-component"
import Layout from "../components/layout-component"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"

const Tenido = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleTenido" })}`}
        description={`${intl.formatMessage({ id: "descTenido" })}`}
        keywords={[
          `${intl.formatMessage({ id: "TenidoKeyword1" })}`,
          `${intl.formatMessage({ id: "TenidoKeyword2" })}`,
          `${intl.formatMessage({ id: "TenidoKeyword3" })}`,
        ]}
        lang={intl.locale}
      />
      <StyledHero img={data.tenidoHero.childImageSharp.fluid} />
      <TenidoComponent />
    </Layout>
  )
}

export const query = graphql`
  query {
    tenidoHero: file(relativePath: { eq: "sombrerosFondo3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Tenido
